<template>
  <!-- Breadcroumbs start -->
  <Breadcrumbs title="My Cart" />
  <!-- Breadcroumbs end -->
  <!-- Shopping Cart start -->
  <div class="bee-content-block">
    <div class="container">
      <div class="row">
        <div class="col-12">
          <div class="my-account-page-title">
            <h3>Your Shopping Cart (3 items)</h3>
          </div>
          <div
            v-for="(item, index) in this.$store.state.cart"
            :key="index"
            class="order-section"
          >
            <div class="order-top justify-content-end">
              <div class="order-id mr-auto">
                Added to cart: <b>11 November 2017 </b>
              </div>
              <div class="delete-order">
                <a
                  ><i
                    class="fa fa-trash-o"
                    aria-hidden="true"
                    @click.prevent="this.$store.commit('removeFromCart', item)"
                  ></i
                ></a>
              </div>
            </div>
            <div class="order-details">
              <div class="order-product">
                <div class="order-product-img">
                  <img :src="item.productImage" alt="" />
                </div>
                <div class="order-product-title">
                  <a href="">
                    <h5>{{ item.productName }}</h5>
                    <p>
                      Lorem Ipsum is simply dummy text of the printing and
                      typesetting industry. Lorem Ipsum has been the industry's
                      standard dummy text.
                    </p>
                  </a>
                </div>
              </div>
              <div class="order-status">
                Price: <b> {{ currency(item.productPrice) }}</b>
                <div class="quantity">
                  <span>Quantity:</span>
                  <span>
                    <input
                      v-model="item.productQuantity"
                      type="text"
                      class="form-control"
                    />
                  </span>
                </div>
              </div>
              <div class="order-action">
                Subtotal:
                <b>{{ subTotal(item.productQuantity, item.productPrice) }}</b>
                <a href="" class="btn-action btn-buy">Buy Now</a>
              </div>
            </div>
          </div>

          <div class="cart-total-section">
            <h3>Cart totals</h3>
            <div class="cart-total-block">
              <ul>
                <li>
                  <span>Subtotal:</span
                  ><span>{{ this.$store.getters.totalPrice }}</span>
                </li>
                <li><span>Shipping:</span><span>$1.00</span></li>
                <li>
                  <span>All Total:</span
                  ><span>{{ this.$store.getters.totalPrice }}</span>
                </li>
              </ul>
            </div>

            <router-link class="btn btn-submit" to="/checkout">
              Proceed to checkout</router-link
            >
          </div>
        </div>
      </div>
    </div>
  </div>
  <!-- Shopping Cart End -->

  <Newsletter />
</template>

<script>
// @ is an alias to /src
import { auth, profilesCollection } from "@/includes/firebase";

import Breadcrumbs from "@/components/Breadcrumbs.vue";
// import ProfileNavigation from "@/components/ProfileNavigation.vue";
import Newsletter from "@/components/Newsletter.vue";
import helpers from "@/includes/helpers";

export default {
  name: "MyCart",
  components: {
    Breadcrumbs,
    Newsletter,
  },
  methods: {
    shortDescription(description) {
      return description.substring(3, 200);
    },
    subTotal(qty, price) {
      const sub = qty * price;
      return this.currency(sub);
    },
    currency(value) {
      return helpers.withDecimalAndCurrency(value);
    },
  },
};
</script>
